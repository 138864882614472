import {h} from 'preact';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';

import VerificationCode from "./verificationCode";
import {IntlText, IntlPlaceholder} from "../../translate";

import store from "../store/store";


const ResetPasswordByPassword = (props) => {
    let [account, setAccount] = useState(store.state.account||'');
    let [verificationCode, setVerificationCode] = useState('');
    let [oldPwd, setOldPwd] = useState('');
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        const enterEvent = e => {
            if (e.key !== undefined && (e.key === "Enter" || e.key === 13)) {
                if (account && oldPwd && password && confirmPassword && (password === confirmPassword)) {
                    forgetOldPwd();
                }
            }
            // if (e.key !== undefined && e.key === "Enter" && account && verificationCode && password && confirmPassword && (password === confirmPassword)) {
            //     forgetPass();
            // } else if (e.keyCode !== undefined && e.key === 13 && account && verificationCode && password && confirmPassword && (password === confirmPassword)) {
            //     forgetPass();
            // }
        };
        document.addEventListener("keyup", enterEvent)
        return () => {
            document.removeEventListener("keyup", enterEvent)
        }
    });

    const oldPasswordEl = useRef(null);
    const passwordEl = useRef(null);
    const confirmPasswordEl = useRef(null);

    const onChangeAccount = (e) => {
        setAccount(e.target.value)
    }

    const onChangeOldPwd = useCallback((e) => {
        setOldPwd(e.target.value)
    }, [oldPwd])

    const onBlurOldPwd = useCallback((e) => {
        setOldPwd(e.target.value)
    }, [oldPwd])

    const onChangePassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [password]);

    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }


    const onBlurConfirmPassword = (e) => {
        if (password !== confirmPassword) {
            if (confirmPasswordEl.current) {
                confirmPasswordEl.current.dataset.error = "error"
            } else {
                e.target.parentElement.dataset.error = "error"
            }
        } else {
            if (confirmPasswordEl.current) {
                confirmPasswordEl.current.dataset.error = ""
            } else {
                e.target.parentElement.dataset.error = ""
            }

        }
    }


    const onVerificationPassword = (e) => {
        let reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$");
        if (!reg.test(e.target.value)) {
            if (passwordEl.current) {
                passwordEl.current.dataset.error = "error"
            } else {
                e.target.parentElement.dataset.error = "error"
            }

        } else {
            if (passwordEl.current) {
                passwordEl.current.dataset.error = ""
            } else {
                e.target.parentElement.dataset.error = ""
            }

        }

        if (confirmPassword && password !== confirmPassword) {
            confirmPasswordEl.current.dataset.error = "error"
        } else {
            confirmPasswordEl.current.dataset.error = ""
        }

        return reg.test(e.target.value)
    };

    const forgetOldPwd = () => {
        if (password === confirmPassword) {
            // noinspection TypeScriptValidateJSTypes
            props.forgetOldPwd(account, oldPwd, password, confirmPassword)
        } else {
            confirmPasswordEl.current.dataset.error = "error"
        }
        // noinspection TypeScriptValidateJSTypes
    }
    return (
        <div>
            <div className="row account">
                <IntlPlaceholder id="forgetPassword.placeholder.userForPwd">
                    <input className="account" type="text"
                           placeholder={"请输入邮箱/手机号/用户名"} onInput={onChangeAccount}
                           value={account}/>
                </IntlPlaceholder>
            </div>
            <div className="row password" data-error="" ref={oldPasswordEl}>
                <IntlPlaceholder id="forgetPassword.placeholder.oldPassword">
                    <input className="password" type="password" placeholder="请输入原密码"
                           onInput={onChangeOldPwd} onBlur={onBlurOldPwd}
                           value={oldPwd}/>
                </IntlPlaceholder>
            </div>
            <div className="row password" data-error="" ref={passwordEl}>
                <IntlPlaceholder id="forgetPassword.placeholder.newPassword">
                    <input className="password" type="password" placeholder="请输入至少6个字符的密码"
                           onInput={onChangePassword} onBlur={onVerificationPassword}
                           value={password}/>
                </IntlPlaceholder>
            </div>
            <div className="row password_tip">
                <IntlText id="forgetPassword.tip.password">
                    <p>请输入至少6个字母和数字组合的密码</p>
                </IntlText>
            </div>
            <div className="row confirm_password" data-error="" ref={confirmPasswordEl}>
                <IntlPlaceholder id="forgetPassword.placeholder.confirmPassword">
                    <input className="account" type="password" placeholder="请再次输入密码" onBlur={onBlurConfirmPassword}
                           onInput={onChangeConfirmPassword}
                           value={confirmPassword}/>
                </IntlPlaceholder>
            </div>
            <div className="row confirm_password_tip">
                <IntlText id="forgetPassword.tip.confirmPassword">
                    <p>两次输入的密码不一样</p>
                </IntlText>
            </div>
            <div className="row btn_login">
                <IntlText id="forgetPassword.btn.reset">
                    <div className="btn_login"
                         disabled={!(account && verificationCode && password && confirmPassword)
                         && !(account && oldPwd && password && confirmPassword)}
                         onClick={forgetOldPwd}>完成重置
                    </div>
                </IntlText>
            </div>
            <div className="row btn_toggle">
                <IntlText id="forgetPassword.btn.account">
                    <div className="sign_up" data-lang={props.locale}
                         onClick={() => props.dispatch({type: "TOGGLE_PANEL", payload: "mobile"})}>返回登录/注册
                    </div>
                </IntlText>
            </div>
        </div>
    )
}


const ResetPasswordByCode = (props) => {
    let [account, setAccount] = useState('');
    let [verificationCode, setVerificationCode] = useState('');
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');

    const verificationInputEle = useRef<HTMLInputElement>(null);

    const forgetPass = () => {
        if (password === confirmPassword) {
            props.forgetPass(account, verificationCode, password, confirmPassword)
        } else {
            confirmPasswordEl.current.dataset.error = "error"
        }
    }

    const passwordEl = useRef(null);
    const confirmPasswordEl = useRef(null);

    const onChangeAccount = (e) => {
        setAccount(e.target.value)
    }

    const onChangePassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [password]);

    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const onVerificationCode = (e) => {
        setVerificationCode(e.target.value)
    }

    const onBlurConfirmPassword = (e) => {
        if (password !== confirmPassword) {
            if (confirmPasswordEl.current) {
                confirmPasswordEl.current.dataset.error = "error"
            } else {
                e.target.parentElement.dataset.error = "error"
            }
        } else {
            if (confirmPasswordEl.current) {
                confirmPasswordEl.current.dataset.error = ""
            } else {
                e.target.parentElement.dataset.error = ""
            }

        }
    }


    const getVerificationCode = () => {
        return props.getVerificationCode(account)
    };

    const onVerificationPassword = (e) => {
        let reg = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$");
        if (!reg.test(e.target.value)) {
            if (passwordEl.current) {
                passwordEl.current.dataset.error = "error"
            } else {
                e.target.parentElement.dataset.error = "error"
            }

        } else {
            if (passwordEl.current) {
                passwordEl.current.dataset.error = ""
            } else {
                e.target.parentElement.dataset.error = ""
            }
        }

        if (confirmPassword && password !== confirmPassword) {
            confirmPasswordEl.current.dataset.error = "error"
        } else {
            confirmPasswordEl.current.dataset.error = ""
        }

        return reg.test(e.target.value)
    };

    return (
        <div>
            <div className="row account">
                <IntlPlaceholder id="forgetPassword.placeholder.user">
                    <input className="account" type="text"
                           placeholder={"请输入邮箱/手机号"} onInput={onChangeAccount}
                           value={account}/>
                </IntlPlaceholder>
            </div>
            <div className="row verification_code">
                <IntlPlaceholder id="forgetPassword.placeholder.code">
                    <input className="password" type="text" placeholder="请输入验证码" ref={verificationInputEle}
                           onInput={onVerificationCode}
                           value={verificationCode}/>
                </IntlPlaceholder>
                <VerificationCode account={account} getVerificationCode={getVerificationCode}
                                  verificationInputEle={verificationInputEle}/>
            </div>
            <div className="row password" data-error="" ref={passwordEl}>
                <IntlPlaceholder id="forgetPassword.placeholder.newPassword">
                    <input className="password" type="password" placeholder="请输入至少6个字符的密码"
                           onInput={onChangePassword} onBlur={onVerificationPassword}
                           value={password}/>
                </IntlPlaceholder>
            </div>
            <div className="row password_tip">
                <IntlText id="forgetPassword.tip.password">
                    <p>请输入至少6个字母和数字组合的密码</p>
                </IntlText>
            </div>
            <div className="row confirm_password" data-error="" ref={confirmPasswordEl}>
                <IntlPlaceholder id="forgetPassword.placeholder.confirmPassword">
                    <input className="account" type="password" placeholder="请再次输入密码" onBlur={onBlurConfirmPassword}
                           onInput={onChangeConfirmPassword}
                           value={confirmPassword}/>
                </IntlPlaceholder>
            </div>
            <div className="row confirm_password_tip">
                <IntlText id="forgetPassword.tip.confirmPassword">
                    <p>两次输入的密码不一样</p>
                </IntlText>
            </div>
            <div className="row btn_login">
                <IntlText id="forgetPassword.btn.reset">
                    <div className="btn_login"
                         disabled={!(account && verificationCode && password && confirmPassword)
                         && !(account && password && confirmPassword)}
                         onClick={forgetPass}>完成重置
                    </div>
                </IntlText>
            </div>
            <div className="row btn_toggle">
                <IntlText id="forgetPassword.btn.account">
                    <div className="sign_up" data-lang={props.locale}
                         onClick={() => props.dispatch({type: "TOGGLE_PANEL", payload: "mobile"})}>返回登录/注册
                    </div>
                </IntlText>
            </div>
        </div>
    )
}

const ForgetPassword = (props) => {
    let [resetByToken, setResetByToken] = useState(props.panel.indexOf("resetPasswordByPassword") === -1);
    // let [verificationCode, setVerificationCode] = useState('');
    // let [oldPwd, setOldPwd] = useState('');


    const onResetByToken = () => {
        setResetByToken(true)
        // setOldPwd('')
    }

    const onResetByPwd = () => {
        setResetByToken(false)
        // setVerificationCode('')
    }


    return (
        <div className="forget_password">
            <div className="mask">
                <div className="layout">
                    <div className="row title">
                        <IntlText id="forgetPassword.title">
                            <div className="title">重置密码</div>
                        </IntlText>
                    </div>
                    <ul className="row tab">
                        <li className={resetByToken ? "active" : ""}>
                            <IntlText id="forgetPassword.resetCode">
                                <span onClick={onResetByToken}>验证码重置</span>
                            </IntlText>
                        </li>
                        <li className={!resetByToken ? "active" : ""}>
                            <IntlText id="forgetPassword.resetPassword"><span onClick={onResetByPwd}>旧密码重置</span>
                            </IntlText>
                        </li>
                    </ul>
                    {
                        resetByToken ? <ResetPasswordByCode {...props}/> : <ResetPasswordByPassword {...props}/>
                    }

                </div>
            </div>
        </div>
    )
};

export default ForgetPassword;
