class Store {
    constructor() {
        this.subscribers = [];
        this.state = {};
    }

    subscribe(name, callback, subscriber, init) {
        this.state[name] = init || null;
        // if(this.subscribers.findIndex(item=>item.name===name) !==-1) return false;
        this.subscribers.push({
            name,
            callback: (...args) => new Promise((resolve, reject) => {
                callback(...args);
                resolve(args)
            }), subscriber: subscriber || "anonymous"
        })
    }

    unsubscribe(name, subscriber) {
        if (subscriber) {
            this.subscribers = this.subscribers.filter(item => !((item.subscriber === subscriber) && (item.name === name)))
        } else {
            this.subscribers = this.subscribers.filter(item => item.name !== name)
        }
    }

    publish(name, ...args) {
        return Promise.all(this.subscribers.map(item => {
            if (item.name === name) {
                return item.callback(...args).then((res) => {
                    this.state[name] = args[0];
                    return Promise.resolve(res)
                })
            }
        }))
    }
}

let store = new Store()

export default store