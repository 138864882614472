
export const objectToQueryString = (obj:object,modify?:boolean) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    if(modify){
        return `${str.join("&")}`;
    }else if(Object.keys(obj).length){
        return `?${str.join("&")}`
    }else {
        return ""
    }
};


interface Options {
    url:string
    method:string
    headers?:object
    data?:any
    getProgress?:(progress)=>{}
}

export const executeRequest = ({url, method, headers, data, getProgress}:Options) => new Promise<any>((resole, reject):any => {
    let xhr = new XMLHttpRequest();
    if (headers && headers['ameAuthorization']) {
        xhr.withCredentials = true;
    }
    xhr.open(method, url, true);

    xhr.onerror = (e) => {
        try {
            const resp = xhr.responseText;
            reject(resp);
        } catch (e) {
            reject(e);
        }
    };

    xhr.upload.onprogress = e => {
        if (getProgress) {
            getProgress(e.loaded / e.total)
        }
    };

    xhr.onreadystatechange = (e) => {
        try {
            if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400) {
                const resp = xhr.responseText;
                resole(resp);
            } else if (xhr.readyState === 4 && xhr.status === 401) {

            } else if(xhr.readyState === 4 && xhr.status >= 400){
                reject(xhr);
            }
        } catch (e) {
            reject(e);
        }
    };
    if (headers) {
        for (let key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }
    }

    xhr.send(data);
});


class HttpClient {
    baseUrl: string;

    request(option: Options) {

    }

    responseError(err:any) {

    }

    response(res:any) {

    }

    constructor(baseUrl) {
        this.baseUrl = baseUrl || ""
    }

    get(url, params, headers):any {
        url = this.baseUrl + url;
        if (params) {
            url = `${url}${objectToQueryString(params)}`
        }
        let option = {url, method: 'GET', headers};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }

    post(url, data, headers?):any {
        url = this.baseUrl + url;
        data = JSON.stringify(data);
        let option = {url, method: 'POST', headers, data};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }

    postFormUrlEncode(url, data):any {
        url = this.baseUrl + url;
        let headers ={
            'Content-Type':'application/x-www-form-urlencoded'
        };
        data = objectToQueryString(data,true);
        let option = {url, method: 'POST', headers, data};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }


    put(url, data, headers):any {
        url = this.baseUrl + url;
        data = JSON.stringify(data);
        let option = {url, method: 'PUT', headers, data};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }

    upload(url, data, headers, getProgress):any {
        url = this.baseUrl + url;
        data = JSON.stringify(data);
        let option = {url, method: 'PUT', headers, data, getProgress};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }

    delete(url, headers):any {
        url = this.baseUrl + url;
        let option = {url, method: 'DELETE', headers};
        this.request(option);
        return executeRequest({...option}).then(this.response, this.responseError);
    }

    load(url, headers?):any {
        console.info(url)
        let option = {url, method: 'GET', headers};
        this.request(option);
        console.info("after",url)
        return executeRequest({...option}).then(this.response, this.responseError);
    }

}

export default HttpClient;
