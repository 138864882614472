import {h, Component} from "preact";
import "./notice.scss"

export interface NotificationProps {
    id?: number;
    severity: string;
    message: string;
    duration?: number
}

interface NotificationState {
    id?: number;
    severity: string;
    message: string;
    duration?: number
}

interface NoticeState {
    queue: Array<NotificationState>
}

interface NoticeProps {
}

class Notification extends Component<NotificationProps, NotificationState> {
    constructor(props: NotificationProps) {
        super(props);

        this.state = {
            severity: props.severity,
            message: props.message,
            duration: props.duration
        };
    }

    componentDidMount() {
        // setTimeout(() => {
        //     var state = this.state;
        //     state.name = "Preact's componentDidMount worked as expected";
        //     this.setState(state);
        // }, 2000);
    }

    render(props: NotificationProps, state: NotificationState) {
        return <div className={`${props.severity} container`}>
            <div className="content" dangerouslySetInnerHTML={{__html:props.message}}></div>
        </div>;
    }
}

let notice;
export default class Notice extends Component<NoticeProps, NoticeState> {
    constructor() {
        super();
        this.state = {
            queue: []
        };
    }


    componentDidMount(): void {
        notice = this;
    }

    static create({severity, message, duration}: NotificationState) {
        return new Promise((resolve) => {
            let {queue} = notice.state;
            let key = queue.length
            queue.push({severity, message, id: key});
            // noinspection TypeScriptValidateJSTypes
            notice.setState({queue}, () => {
                setTimeout(() => {
                    notice.close(key)
                }, ((key + 1) * duration) || ((key + 1) * 1000 + 3000))
            })
            resolve(key)
        })
    }

    close(key) {
        let {queue} = this.state;
        queue.splice(queue.findIndex(item => item.id === key), 1)
        this.setState({queue})
    }

    render(props: NoticeProps, state: NoticeState) {
        return <div className="notice">
            {
                this.state.queue.map((item, key) => <Notification key={key} {...item}/>)
            }
        </div>;
    }
}