import {render, h, createContext} from 'preact';
import {useCallback, useEffect, useReducer, useRef, useContext, useState, useMemo} from 'preact/hooks';

const useForceUpdate = () => {
    const [, forceUpdate] = useState(null);

    return useCallback(() => {
        forceUpdate(s => !s)
    }, [])
};

interface Provide {
    locale: string,
    data: object
}
interface Consumer {
    locale: string,
    data: object
}

let Intl;

const createIntlContext=()=> {
    return createContext(null);
}

export const IntlProvider =(props)=> {
    Intl = createIntlContext();
    return props.children ?
        <Intl.Provider key={new Date().getTime()} value={{locale: props.locale, data: props.data}}>{props.children}</Intl.Provider> : null
}


export const IntlText = (props)=> {
    // const forceUpdate = useForceUpdate();
    // const [state, setState] = useState(null);
    const intl: Consumer = useContext(Intl);
    if (!Object.keys(intl.data).length) return props.children
    try {
        let memoizedValue = useMemo(() => props.id.split('.').reduce((o, i) => o[i], intl.data), [intl.locale,props.id]);
        if (Object.prototype.toString.call(props.children.props.children) === "[object Array]") {
            props.children.props.children = props.children.props.children.map(item => {
                if (item === props.id.match(/(?!=\.)[^.]+$/)[0]) {
                    return memoizedValue
                } else {
                    return item
                }
            })
        } else if(memoizedValue) {
            props.children.props.children = memoizedValue;
        }
        return props.children
    }catch (e) {
        console.log(e)
    }
}


export const IntlPlaceholder=(props)=> {
    try {
        const intl: Consumer = useContext(Intl);
        if (!Object.keys(intl.data).length) return props.children
        const memoizedValue = useMemo(() => props.id.split('.').reduce((o, i) => o[i], intl.data), [intl.locale,props.id]);
        props.children.props.placeholder = memoizedValue;
        return props.children
    }catch (e) {
        console.log(e)
    }
}