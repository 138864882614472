import {render, h, Fragment} from 'preact';
import {useEffect, useReducer} from 'preact/hooks';

import Account from "./components/account";
import SignUp from "./components/signUp";
import Mobile from "./components/mobile";
import ForgetPassword from "./components/forgetPassword";

import "./style/main.scss";


import {LoginHttp} from "../restful/api/restApi"

import Notice from "../common/notice"

import {IntlProvider} from "../translate";
import JSX = preact.h.JSX;

import store from "./store/store";

import en from "./i18n/en-US.json";
import zh from "./i18n/zh-CN.json";


let storageListener;


function init({name, locale}) {
    return {
        name,
        locale,
        definition: {}
    };
}

function reducer(state, action) {
    switch (action.type) {
        case "TOGGLE_PANEL":
            return {...state, name: action.payload};
        case "TOGGLE_LOCALE":
            return {...state, locale: action.payload.locale};
        case "READ_LOCALE_DATA":
            return {...state, definition: action.payload};
        case "TRANSLATE":
            return {...state, ...action.payload};
        default:
            throw new Error();
    }
}


const App = (props): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, {name: props.name, locale: props.locale}, init);

    /***
     * @description 验证密码强度
     * @param password
     */
    const validatePwdStrength = (password) => {
        let regex = new RegExp("^(?![0-9]+$)(?![a-zA-Z]+$)(?![\\W]+$)[^\\s]{6,}$");
        return new Promise((resolve, reject) => {
            if (regex.test(password)) {
                resolve()
            }
            reject()
        })
    };


    // @ts-ignore
    App.signIn = function (name) {
        dispatch({type: "TOGGLE_PANEL", payload: name})
    };

    // @ts-ignore
    App.signUp = function (name) {
        dispatch({type: "TOGGLE_PANEL", payload: name})
    };

    // @ts-ignore
    App.handle = function (name) {
        dispatch({type: "TOGGLE_PANEL", payload: name})
    };

    // @ts-ignore
    App.close = function () {
        closeLogin()
    };

    useEffect(() => {
        store.subscribe("account", () => {
        }, "main", "")
    }, []);


    useEffect(() => {
        if (!props.name && !storageListener) {
            let originalSetItem = localStorage.setItem;
            localStorage.setItem = function (k, v) {
                window.addEventListener("setItemEvent", onChangeLocale);
                let setItemEvent = new CustomEvent("setItemEvent", {detail: {key: k, value: v}});
                window.dispatchEvent(setItemEvent);
                originalSetItem.apply(this, arguments);

            }
        }
        storageListener = true

    }, []);

    useEffect(() => {
        if (state.locale === "zh-CN") {
            dispatch({type: "TRANSLATE", payload: {definition: zh, locale: state.locale}})
        } else {
            dispatch({type: "TRANSLATE", payload: {definition: en, locale: state.locale}})
        }
    }, []);

    useEffect(() => {
        let inputs = document.getElementsByTagName("input");
        let ua = window.navigator.userAgent;
        if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPad') > 0) {
            Array.prototype.forEach.call(inputs, ele => {
                ele.onblur = () => {
                    window.scrollTo(0, 0);
                }
            })
        }
    });

    const onChangeLocale = (e) => {
        if (e.detail.key !== "locale") return false;
        if (e.detail.value === "en-US") {
            dispatch({type: "TRANSLATE", payload: {definition: en, locale: e.detail.value}})
        } else {
            dispatch({type: "TRANSLATE", payload: {definition: zh, locale: e.detail.value}})
        }
    };


    const login = (name, password) => {
        validatePwdStrength(password).then(() => {
            LoginHttp.post(props.signInApi, {name, password}).then(res => {
                if (props.notice) {
                    Notice
                        .create({severity: "success", message: "登录成功！<br/> Login successful!"})
                        .then(() => {
                            if (props.loginCallback) {
                                props.loginCallback({type: "resolve", payload: res})
                            }
                        })
                } else if (props.loginCallback) {
                    props.loginCallback({type: "resolve", payload: res})
                }
                setTimeout(closeLogin, 0)
            }, rej => {
                if (props.notice) {
                    Notice
                        .create({severity: "warning", message: rej.msg})
                        .then(() => {
                            if (props.loginCallback) {
                                props.loginCallback({type: "reject", payload: rej})
                            }
                        })
                } else if (props.loginCallback) {
                    props.loginCallback({type: "reject", payload: rej})
                }
            })
        }, () => {
            if (props.notice) {
                Notice
                    .create({
                        severity: "warning",
                        message: '密码为过于简单，请重置密码。<br/>The password is too simple, please reset the password'
                    })
                    .then(() => {
                        if (props.validPwdCallback) {
                            props.validPwdCallback({type: "reject", payload: {}})
                        }
                    })
            } else if (props.validPwdCallback) {
                dispatch({type: "TOGGLE_PANEL", payload: "forget.resetPasswordByPassword"});
                props.validPwdCallback({
                    type: "reject",
                    payload: {message: '密码为过于简单，请重置密码。<br/>The password is too simple, please reset the password'}
                })
            }
        })
    };

    const mobileLoginApi = (account, code) => {
        let data = {account, code, timestamp: new Date().getTime()};
        LoginHttp.post(props.mobileLoginApi, data).then(res => {
            if (props.notice) {
                Notice
                    .create({severity: "success", message: "登录成功！<br/> Login successful!"})
                    .then(() => {
                        if (props.loginCallback) {
                            props.loginCallback({type: "resolve", payload: res})
                        }
                    })
            } else if (props.loginCallback) {
                props.loginCallback({type: "resolve", payload: res})
            }
            setTimeout(closeLogin, 0)

        }, rej => {
            if (props.notice) {
                Notice
                    .create({severity: "warning", message: rej.msg})
                    .then(() => {
                        if (props.loginCallback) {
                            props.loginCallback({type: "reject", payload: rej})
                        }
                    })
            } else if (props.loginCallback) {
                props.loginCallback({type: "reject", payload: rej})
            }
        })
    };

    const forgetPass = (account, code, password, confirmPassword) => {
        let data = {account, code, password, confirmPassword, timestamp: new Date().getTime()};
        LoginHttp.post(props.forgetPass, data).then(res => {
            if (props.notice) {
                Notice
                    .create({severity: "success", message: "修改密码成功！<br/>Your password has been successfully changed."})
                    .then(() => {
                        if (props.forgetPassCallback) {
                            props.forgetPassCallback({type: "resolve", payload: res})
                        }
                    })
            } else if (props.forgetPassCallback) {
                props.forgetPassCallback({type: "resolve", payload: res})
            }
            // closeLogin()
            dispatch({type: "TOGGLE_PANEL", payload: "account"})

        }, rej => {
            if (props.notice) {
                Notice
                    .create({severity: "warning", message: rej.msg})
                    .then(() => {
                        if (props.forgetPassCallback) {
                            props.forgetPassCallback({type: "reject", payload: rej})
                        }
                    })
            } else if (props.forgetPassCallback) {
                props.forgetPassCallback({type: "reject", payload: rej})
            }
        })
    };

    const forgetOldPwd = (account, oldPwd, password, confirmPassword) => {
        let data = {
            account,
            old_pwd: oldPwd,
            pwd: password,
            pwd_confirmation: confirmPassword,
            service: props.service
        };
        LoginHttp.post(props.forgetOldPwd, data).then(res => {
            if (props.notice) {
                Notice
                    .create({severity: "success", message: "修改密码成功！<br/>Your password has been successfully changed."})
                    .then(() => {
                        if (props.forgetPassCallback) {
                            props.forgetPassCallback({type: "resolve", payload: res})
                        }
                    })
            } else if (props.forgetPassCallback) {
                props.forgetPassCallback({type: "resolve", payload: res})
            }
            // closeLogin()
            dispatch({type: "TOGGLE_PANEL", payload: "account"})

        }, rej => {
            if (props.notice) {
                Notice
                    .create({severity: "warning", message: rej.msg})
                    .then(() => {
                        if (props.forgetPassCallback) {
                            props.forgetPassCallback({type: "reject", payload: rej})
                        }
                    })
            } else if (props.forgetPassCallback) {
                props.forgetPassCallback({type: "reject", payload: rej})
            }
        })
    };

    const getVerificationCode = (account): Promise<any> => {
        let data = {
            account,
            timestamp: new Date().getTime(),
            locale: state.locale
        };
        return LoginHttp.post(props.verificationCodeApi, data)
            .then(res => {
                if (props.notice) {
                    Notice
                        .create({severity: "success", message: "发送验证码成功 <br/>Security code sent!"})
                        .then(() => {
                            if (props.getVerificationCodeCallback) {
                                props.getVerificationCodeCallback({type: "resolve", payload: res})
                            }
                        })
                } else if (props.getVerificationCodeCallback) {
                    props.getVerificationCodeCallback({type: "resolve", payload: res})
                }
                return Promise.resolve()
            }, rej => {
                if (props.notice) {
                    Notice
                        .create({severity: "warning", message: rej.msg})
                        .then(() => {
                            if (props.getVerificationCodeCallback) {
                                props.getVerificationCodeCallback({type: "reject", payload: rej})
                            }
                        })
                } else if (props.getVerificationCodeCallback) {
                    props.getVerificationCodeCallback({type: "reject", payload: rej})
                }
                return Promise.reject(rej)
            })
    };


    const revertBodyScroll = () => {
        document.body.removeAttribute("style");
    };

    // const openSignIn = useCallback(() => {
    //     dispatch({type: "TOGGLE_PANEL", payload: "account"})
    // }, [state.name]);
    //
    // const openSignUp = useCallback(() => {
    //     dispatch({type: "TOGGLE_PANEL", payload: "mobile"})
    // }, [state.name]);

    const close = e => {
        if (e.target.className == "mask") {
            closeLogin()
        }
    };


    const closeLogin = () => {
        dispatch({type: "TOGGLE_PANEL", payload: ""});
        revertBodyScroll()
    };

    return (
        <Fragment>
            <IntlProvider locale={state.locale} data={state.definition}>
                <div className="login" onClick={e => {
                    e.stopImmediatePropagation();
                    close(e)
                }}>
                    {
                        state.name === "account" ?
                            <Account dispatch={dispatch} login={login} {...state}/> :
                            (state.name === "mobile" ?
                                <Mobile dispatch={dispatch} mobileLoginApi={mobileLoginApi}
                                        getVerificationCode={getVerificationCode}
                                        onChangeLocale={onChangeLocale} {...state}/> :
                                (state.name.indexOf("forget") !== -1 ?
                                    <ForgetPassword dispatch={dispatch} getVerificationCode={getVerificationCode}
                                                    panel={state.name}
                                                    forgetPass={forgetPass} forgetOldPwd={forgetOldPwd}  {...state}/> :
                                    (state.name === "signUp" ?
                                        <SignUp dispatch={dispatch} login={login}  {...state}/> : null)))
                    }
                </div>
            </IntlProvider>
            <Notice/>
        </Fragment>
    )
};


export default class AMELogin {
    signInEle: string | HTMLElement;
    signUpEle: string | HTMLElement;
    forgetEle: string | HTMLElement;
    signInApi: string;
    mobileLoginApi: string;
    verificationCodeApi: string;
    forgetPass: string;
    forgetOldPwd: string;
    service: string;
    loginCallback: (p: Object) => {};
    forgetPassCallback: (p: Object) => {};
    getVerificationCodeCallback: (p: Object) => {};
    validPwdCallback: (p: Object) => {};
    name: string;
    locale: string;
    notice: boolean;

    constructor(options) {
        this.signInEle = options.signInEle;
        this.signUpEle = options.signUpEle;
        this.forgetEle = options.forgetEle;
        this.mobileLoginApi = options.mobileLoginApi || "jwtAuth/codeRegisterOrLogin";
        this.signInApi = options.signInApi || "jwtAuth/accountLogin";
        this.forgetPass = options.forgetPass || "jwtAuth/forgetPass";//reset pwd by token
        this.forgetOldPwd = options.forgetOldPwd || "jwtAuth/modifyPwdByOldPwdWithLogout";//reset pwd by old password
        this.verificationCodeApi = options.verificationCodeApi || "jwtAuth/sendMessage";
        this.service = options.service;
        this.loginCallback = options.loginCallback;
        this.forgetPassCallback = options.forgetPassCallback;
        this.getVerificationCodeCallback = options.getVerificationCodeCallback;
        this.validPwdCallback = options.validPwdCallback;
        this.name = "";
        this.locale = options.locale || "zh-CN";
        this.notice = options.notice || false
    }

    disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    openSignIn = () => {
        this.disableBodyScroll();
        // @ts-ignore
        App.signIn("account")
    };

    openSignUp = () => {
        this.disableBodyScroll();
        // @ts-ignore
        App.signUp("mobile")
    };

    openForget = () => {
        this.disableBodyScroll();
        // @ts-ignore
        App.handle("forget")
    };

    closeLogin = () => {
        this.disableBodyScroll();
        // @ts-ignore
        App.close()
    }

    ready() {
        let root = document.createElement("div");
        root.id = "AMELogin";
        render(<App {...this}/>, root);
        document.body.appendChild(root);
        if (Object.prototype.toString.call(this.signInEle) === "[object String]") {
            //@ts-ignore
            let signInEle = document.querySelectorAll(this.signInEle);
            [].forEach.call(signInEle, item => item.addEventListener("click", this.openSignIn))
        }

        if (Object.prototype.toString.call(this.signUpEle) === "[object String]") {
            //@ts-ignore
            let signUpEle = document.querySelectorAll(this.signUpEle);
            [].forEach.call(signUpEle, item => item.addEventListener("click", this.openSignUp))
        }

        if (Object.prototype.toString.call(this.forgetEle) === "[object String]") {
            //@ts-ignore
            let forgetEle = document.querySelectorAll(this.forgetEle);
            [].forEach.call(forgetEle, item => item.addEventListener("click", this.openForget))
        }
    }
}



