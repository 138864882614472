import {h} from 'preact';
import {useState,useRef} from 'preact/hooks';
import {IntlText} from "../../translate"

const VerificationCode = (props) => {
    let [time, setTime] = useState(59);
    let [counter, setCounter] = useState(null);
    const verificationEl = useRef<HTMLLabelElement>(null);

    const countdown = () => {
        verificationEl.current.dataset.fetch = "loading";
        // props.verificationInputEle.current.focus();
        props.getVerificationCode().then(()=>{
            verificationEl.current.dataset.fetch = "";
            let counter = setInterval(() => {
                if (time <= 0) {
                    clearInterval(counter);
                    setCounter(null);
                    setTime(59)
                } else {
                    setTime(time--)
                }
            }, 1000);
            setTime(time--);
            setCounter(counter);
        },(res)=>{
            console.log(res)
            verificationEl.current.dataset.fetch = "";
            if(res.status&&res.status===503){
                props.dispatch({type: "TOGGLE_PANEL", payload: "signUp"})
            }
        })

    };

    return (
        !counter ?
            <IntlText id="account.btn.verification">
                <label id="verification_code"
                       className="verification_code"
                       disabled={!/[\S]+/.test(props.account)}
                       onClick={countdown}
                       data-fetch=""
                       ref={verificationEl}>获取验证码</label>
            </IntlText> :
            <IntlText id="account.btn.verificationCounter">
                <label id="verification_code" className="verification_code">{time}verificationCounter</label>
            </IntlText>
    )
}

export default VerificationCode;