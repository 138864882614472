import {h} from 'preact';
import {useCallback, useEffect, useState} from 'preact/hooks';
import {IntlText,IntlPlaceholder} from "../../translate"

const SignUp = (props) => {
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const [protocol, setProtocol] = useState(true);

    useEffect(() => {
        const enterEvent = e => {
            if (e.key !== undefined && e.key === "Enter" && account && password && protocol) {
                handleLogin();
            } else if (e.keyCode !== undefined && e.key === 13 && account && password && protocol) {
                handleLogin();
            }
        };
        document.addEventListener("keyup", enterEvent)
        return () => {
            document.removeEventListener("keyup", enterEvent)
        }
    });

    const onChangeAccount = useCallback((e) => {
        setAccount(e.target.value)
    }, [account]);


    const onChangePassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [password]);

    const onCheckProtocol = useCallback(() => {
        setProtocol(!protocol)
    }, [protocol]);

    const handleLogin = ()=>{
        props.login(account,password)
    }

    return (
        <div className="login_account">
            <div className="mask">
                <div className="layout">
                    <div className="row title">
                        <IntlText id="signUp.title"><div className="title">账号密码登录或注册</div></IntlText>
                    </div>
                    <div className="row account">
                        <IntlPlaceholder id="signUp.placeholder.user">
                            <input className="account" onInput={onChangeAccount} value={account} type="text"
                                   placeholder="请输入邮箱/手机号"/>
                            </IntlPlaceholder>
                    </div>
                    <div className="row password">
                        <IntlPlaceholder id="signUp.placeholder.password">
                            <input className="password" onInput={onChangePassword} value={password} type="password"
                                   placeholder="请输入密码"/>
                            </IntlPlaceholder>
                    </div>
                    <div className="row btn_login">
                        <IntlText id="signUp.btn.signIn">
                            <div className="btn_login" disabled={!(account && password && protocol)} onClick={handleLogin}>登录/注册</div>
                        </IntlText>
                    </div>

                    {
                        props.locale.includes("en") ? null : <div className="row protocol">
                            <div className="protocol">
                                <div className="protocol_check">
                                    <input id="agree_protocol" type="checkbox" value="agree" checked={protocol} data-protocol={protocol} />
                                    <label className="agree_protocol_label" htmlFor="agree_protocol"
                                           onClick={onCheckProtocol}/>
                                </div>
                                <IntlText id="signUp.protocol">
                                    <div className="protocol_text">protocol
                                        <IntlText id="mobile.protocolContent">
                                            <a href="//auth.amegroups.cn/protocal"
                                               target="_blank">protocolContent</a>
                                        </IntlText>
                                    </div>
                                </IntlText>
                                {/*<div className="protocol_text">勾选即表示同意<a href="//auth.amegroups.cn/protocal" target="_blank">《AME会员协议》</a></div>*/}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp;