import HttpClient from "./http_client";

// 请求拦截
HttpClient.prototype.request = function (option) {
    option.headers = {
        "Content-Type": "application/json",
        "ameAuthorization":localStorage.token,
        "ame-referer": 'http://atm.amegroups.net',
        ...option.headers
    }
};

// 响应拦截
HttpClient.prototype.response = function (res:string):Promise<object|string> {
    try{
        let data:object = JSON.parse(res);
        return Promise.resolve(data)
    } catch (e) {
        return Promise.resolve(res)
    }

};
// 错误响应拦截
HttpClient.prototype.responseError = function (err) {
    try{
        let data = JSON.parse(err.responseText);
        data = Object.assign({...data,status:err.status});
        return Promise.reject(data)
    }catch (e) {
        return Promise.reject({msg:"接口定义错误",status:err.status});
    }

};


class RestHttpClient extends HttpClient{}

RestHttpClient.prototype.responseError = function (err) {
    try{
        let data = JSON.parse(err.responseText);
        data = data?.error;
        return Promise.reject(data)
    }catch (e) {
        return Promise.reject({msg:"接口定义错误",status:err?.status});
    }

};

const LoginHttp = new HttpClient("/api/");

const BlackListHttp = new HttpClient("");

const Api = new HttpClient("/api");
const  RestApi= new RestHttpClient("/api");


export {
    LoginHttp,
    BlackListHttp,
    Api,
    RestApi
}
