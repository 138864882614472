import {h} from 'preact';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import VerificationCode from "./verificationCode";

import {IntlText,IntlPlaceholder} from "../../translate"

const Mobile = (props) => {
    let [time, setTime] = useState(59);
    let [counter, setCounter] = useState(null);
    let [account, setAccount] = useState('');
    let [verificationCode, setVerificationCode] = useState('');
    let [protocol, setProtocol] = useState(true);
    const verificationInputEle = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const enterEvent = e => {
            if (e.key !== undefined && e.key === "Enter" && account && verificationCode && protocol) {
                mobileLoginApi();
            } else if (e.keyCode !== undefined && e.key === 13 && account && verificationCode && protocol) {
                mobileLoginApi();
            }
        };
        document.addEventListener("keyup", enterEvent)
        return () => {
            document.removeEventListener("keyup", enterEvent)
        }
    });

    const onChangeAccount = useCallback((e) => {
        setAccount(e.target.value)
    }, [account]);


    const onVerificationCode = useCallback((e) => {
        setVerificationCode(e.target.value)
    }, [verificationCode]);

    const onCheckProtocol = useCallback(() => {
        setProtocol(!protocol)
    }, [protocol]);

    const getVerificationCode = () => {
        return props.getVerificationCode(account)
    };

    const mobileLoginApi = () => {
        props.mobileLoginApi(account, verificationCode)
    }


   console.log(props)


    return (
        <div className="sign_in_mobile">
            <div className="mask">
                <div className="layout">
                    <div className="row title">
                        <IntlText id="mobile.title"><div className="title">验证码登录/注册</div></IntlText>
                    </div>
                    <div className="row account">
                        <IntlPlaceholder id="mobile.placeholder.user"><input className="account" type="text" placeholder="请输入邮箱/手机号" onInput={onChangeAccount}
                                                value={account}/></IntlPlaceholder>
                    </div>
                    <div className="row password">
                        <IntlPlaceholder id="mobile.placeholder.code">
                            <input className="password" ref={verificationInputEle} type="text" placeholder="请输入验证码"
                               onInput={onVerificationCode}
                               value={verificationCode}/>
                        </IntlPlaceholder>
                        <VerificationCode account={account} getVerificationCode={getVerificationCode}
                                          verificationInputEle={verificationInputEle} dispatch={props.dispatch}/>
                    </div>
                    <div className="row btn_login">
                        <IntlText id="mobile.btn.signIn"><div className="btn_login" disabled={!(account && verificationCode && protocol)}
                             onClick={mobileLoginApi}>登录/注册
                        </div></IntlText>
                    </div>
                    <div className="row sign_up_tip">
                        <IntlText id="mobile.tip">
                            <div className="sign_up_tip_content">未注册用户验证成功后自动注册</div>
                        </IntlText>
                    </div>
                    <div className="row btn_toggle">
                        <IntlText id="mobile.btn.toggle"><div className="sign_up" data-lang={props.locale}
                             onClick={() => props.dispatch({type: "TOGGLE_PANEL", payload: "account"})}>密码登录
                        </div></IntlText>
                    </div>
                    {
                        props.locale.includes("en") ? null :<div className="row protocol">
                        <div className="protocol">
                            <div className="protocol_check">
                                <input id="agree_protocol" type="checkbox" checked={protocol} data-protocol={protocol}/>
                                <label
                                    onClick={onCheckProtocol}
                                    className="agree_protocol_label" htmlFor="agree_protocol"/>
                            </div>
                            <IntlText id="mobile.protocol">
                                <div className="protocol_text">protocol
                                    <IntlText id="mobile.protocolContent">
                                        <a href="//auth.amegroups.cn/protocal"
                                           target="_blank">protocolContent</a>
                                    </IntlText>
                                </div>
                            </IntlText>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
};

export default Mobile;
